import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import ComponentsForm from "../../../components/ComponentsForm";
import FinishedGoodDetailsCard from "../../../components/FinishedGoodDetailsCard/FinishedGoodDetailsCard";
import FixedScrollToBottomButton from "../../../components/FixedScrollToBottomButton/FixedScrollToBottomButton";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useCreateBOMMutation } from "../../../features/api/boms";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import { COMPONENT_DETAILS_STEP } from "../constants";

function ComponentDetails() {
  const [finishedGood, setFinishedGood] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [createBOM, { isError, isSuccess, data: newBOM }] = useCreateBOMMutation();

  function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    let errorMsg;

    for (let i = 0; i < values.components.length - 1; i += 1) {
      const component = values.components[i];
      const { Category: category, Hts: hts, Origin: origin } = component;
      if (category === "Material" && hts === "") {
        errorMsg = "Please provide HTS for every Material component.";
        break;
      }

      if (category === "Material" && origin === "") {
        errorMsg = "Please specify the origin of all Material components.";
        break;
      }
    }

    if (errorMsg) {
      Swal.fire({
        title: "Error!",
        text: errorMsg,
        icon: "error",
      }).then(() => {
        setSubmitting(false);
      });
    } else {
      const { finishedGood: fg } = location.state;
      const requestBody = {
        currency: fg.currency,
        fg: {
          part_number: fg.partNumber,
          description: fg.description,
          hts: fg.hts,
          origin: fg.origin,
          transaction_value: String(fg.transactionValue),
          net_cost: String(fg.netCost),
          total_cost: String(fg.totalCost),
          questions: [],
        },
        components: values.components.map((component) => ({
          category: component.category,
          hts: component.hts,
          part_number: component.partNumber,
          description: component.description,
          origin: component.origin,
          qty: String(component.qty),
          units: component.units,
          cost: String(component.cost),
          originating: false,
          questions: [],
        })),
      };
      createBOM(requestBody).then(() => setSubmitting(false));
    }
  }

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: "Unable to create BOM. Please try again.",
        icon: "error",
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess && newBOM) {
      navigate(`/dashboard/bom/${newBOM.id}`, { replace: true });
    }
  }, [isSuccess, newBOM, navigate]);

  useEffect(() => {
    if (location) {
      if (location.state && location.state.finishedGood) {
        const { finishedGood: f } = location.state;
        setFinishedGood({
          currency: f.currency,
          partNumber: f.partNumber,
          description: f.description,
          origin: f.origin,
          id: "DRAFT",
          hts: f.hts,
          transactionValue: f.transactionValue,
          netCost: f.netCost,
          totalCost: f.totalCost,
        });
      } else {
        Swal.fire({
          title: "Missing details",
          text: "Please provide details about the finished good first.",
        }).then(() => {
          navigate("/dashboard/create-bill-of-material/fg-details/", { replace: true });
        });
      }
    }
  }, [location, navigate]);

  let body;

  if (!finishedGood) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else {
    body = (
      <div>
        <div className="mb-5">
          <FinishedGoodDetailsCard finishedGood={finishedGood} />
        </div>
        <ComponentsForm handleSubmit={handleSubmit} />
      </div>
    );
  }

  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-lg-2" />
        <div className="col-lg-8">
          <div className="mb-5 d-flex w-100">
            <ProgressBar step={COMPONENT_DETAILS_STEP} />
          </div>
          <div className="mb-5">
            <h1 className="mb-3">Add Component Details</h1>
            <p>Enter the individual components your finished good is made up of.</p>
          </div>
          {body}
        </div>
        <div className="col-lg-2" />
      </div>
      <FixedScrollToBottomButton />
    </div>
  );
}

export default ComponentDetails;
